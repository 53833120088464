<template>
  <div class="row p-0 m-0">
    <div class="col p-0 m-0">
      <div class="p-0 m-0" v-for="(obj, key, index) in items" v-bind:key='index'>
        <div class="card mt-4 w-100" style="width: 18rem;" >
          <div class="card-header">
            <div class="row">
              <div class="col" v-if="key === Constants.TIMESHEET_ENTRY_TYPE_TASK"><strong>Tätigkeiten</strong></div>
              <div class="col" v-if="key === Constants.TIMESHEET_ENTRY_TYPE_BREAK"><strong>Pausen</strong></div>
              <div class="col" v-if="key === Constants.TIMESHEET_ENTRY_TYPE_RIDE"><strong>Fahrten</strong></div>
              <div class="col" v-if="key === Constants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY"><strong>Abwesenheiten</strong></div>
              <div class="col text-end"></div>
            </div>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="{ id, startTime, endTime, task, client, distance, reason, timeOfDay } in obj" :key="id">
              <div class="row">
                <div class="col" v-if="key === Constants.TIMESHEET_ENTRY_TYPE_TASK"><small>{{ task }} &bull; {{ client }}<br/><span class="text-black-50">{{ shortTimeString(startTime) }} - {{ shortTimeString(endTime) }} Uhr</span></small></div>
                <div class="col" v-if="key === Constants.TIMESHEET_ENTRY_TYPE_BREAK"><small>{{ shortTimeString(startTime) }} - {{ shortTimeString(endTime) }} Uhr</small></div>
                <div class="col" v-if="key === Constants.TIMESHEET_ENTRY_TYPE_RIDE"><small>{{ distance }} km<br/><span class="text-black-50">{{ shortTimeString(startTime) }} - {{ shortTimeString(endTime) }} Uhr</span></small></div>
                <div class="col" v-if="key === Constants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY"><small>{{ reason }}<br/><span class="text-black-50">{{ unavailabilityTime(reason, timeOfDay, startTime, endTime) }}</span></small></div>
                <div class="col-1 align-self-center p-0 text-center"><button class="btn btn-sm" @click="deleteItem(id)" v-if="!timeSheetHasBeenSubmitted"><i class="bi bi-x-lg text-start"></i></button></div>
              </div>
            </li>
          </ul>
        </div>
        <div class="text-center">
          <button type="button"
                  class="btn btn-success btn-lg w-100 mt-4 mb-5"
                  data-bs-toggle="modal"
                  data-bs-target="#confirmationDialogModal"
                  v-if="!timeSheetHasBeenSubmitted && Object.keys(items).length === index + 1">
            <i class="bi bi-send-check-fill pe-2"></i>Stundenzettel einreichen
          </button>
          <p class="text-black-50 mt-4 mb-5" v-else-if="timeSheetHasBeenSubmitted && Object.keys(items).length === index + 1"><small>{{ submittedTimesheetHintText }}</small></p>
        </div>
      </div>
      <div class="text-center mt-4 mb-5">
        <p class="text-black-50 mt-5 z-index-n1" v-if="!timeSheetHasBeenSubmitted && !hasItems"><small>{{ emptyTimeSheetHintText }}</small></p>
      </div>
    </div>
  </div>
</template>

<script>
import {deleteItem, readItems} from '@/firebase'
import moment from 'moment'
import { TimeSheetConstants as Constants } from "@/mixins/TimeSheetMixin"
const {MAX_NUMBER_OF_DAYS_FOR_SUBMISSION} = require("@/mixins/DateMixin");

export default {
  props: ['timeSheetHasBeenSubmitted', 'timeSheetSubmissionDate'],
  emits: ["childToParent", "timeRangeChanged"],
  setup() {
    return { Constants }
  },
  data() {
    return {
      items: null,
      isEmpty: true,
      unsubscribeItems: null,
    }
  },
  mounted() {
    console.log("mounted")
    this.loadItems(false)
  },
  watch: {
    '$route'() {
      this.loadItems()
    },
    'items'(value, oldValue) {
      this.$emit('childToParent', false)
      this.$emit('timeRangeChanged', this.getTimeSheetTimeRanges(value))

      if (oldValue && !value) {
        console.log("Loading started ...");
      } else if (!oldValue && value) {
        console.log("Loading finished");
        if (!this.hasItems) {
          console.log("Items are empty")
          this.isEmpty = true
        } else {
          this.isEmpty = false
        }
      }
    }
  },
  methods: {
    loadItems() {
      this.$emit('childToParent', true)

      if (this.unsubscribeItems) { this.unsubscribeItems() }

      const {items, unsubscribeItems} = readItems(this.selectedDate);
      this.items = items
      this.unsubscribeItems = unsubscribeItems
    },
    deleteItem(id) {
      deleteItem(this.selectedDate, id)
    },
    unavailabilityTime(availabilityReason, timeOfDay, startTime, endTime) {
      if (availabilityReason === Constants.UNAVAILABILITY_REASON_OVERHOURS) {
        return this.shortTimeString(startTime) + " - " + this.shortTimeString(endTime) + " Uhr"
      } else {
        return timeOfDay
      }
    }
  },
  computed: {
    selectedDate() {
      return this.selectedDateParam(this.$route.params.date)
    },
    hasItems() {
      if (this.items) {
        return Object.keys(this.items).length > 0
      }
      return false
    },
    addingNewEntryDisabled() {
      const today = moment().locale("de")
      return today.diff(this.selectedDate, 'days') > MAX_NUMBER_OF_DAYS_FOR_SUBMISSION
    },
    emptyTimeSheetHintText() {
      if (this.addingNewEntryDisabled) {
        return "Stundenzettel können nur für maximal " + MAX_NUMBER_OF_DAYS_FOR_SUBMISSION + " Tage nachträglich eingereicht werden."
      } else {
        return "Dieser Stundenzettel ist noch leer. Über den Button 'Hinzufügen' können Sie Einträge hinzufügen."
      }
    },
    submittedTimesheetHintText() {
      return "Dieser Stundenzettel wurde am " + this.dateStringWithLongWeekday(this.timeSheetSubmissionDate) + " eingereicht."
    },
  }
}
</script>
