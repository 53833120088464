<template>
  <div class="container admin-layout-content">
    <div class="ms-3 mb-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/admin/users" v-slot="{href, navigate}">
              <a :href="href" @click="navigate">Mitarbeiter</a>
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="`/admin/users/${id}/timesheets`" v-slot="{href, navigate}">
              <a :href="href" @click="navigate">{{ name }}</a>
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Stundenzettel</li>
        </ol>
      </nav>
    </div>
    <div id="printMe" class="m-0 p-0">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h4 class="card-title">{{ name }}</h4>
            <p class="card-text">Arbeitszeitabrechnung {{ formatMonthAndYear(selectedDate) }} (KW{{currentWeekNumber(selectedDate)}})<br/>Zimmerei Köhnke, Werkstr. 223, 19069 Schwerin</p>
          </div>
          <div class="col p-0 m-0">
            <div class="row align-items-center p-0 m-0 ms-auto me-3" style="width: 160px;">
              <div class="col p-0 m-0 text-start"><button type="button" class="btn btn-secondary" @click="previousWeek"><i class="bi bi-caret-left-fill"></i></button> </div>
              <div class="col p-0 m-0 text-center"><h6 class="mt-2">KW {{ currentWeekNumber(selectedDate) }}</h6></div>
              <div class="col p-0 m-0 text-end"><button type="button" class="btn btn-secondary" @click="nextWeek" v-if="!this.isCurrentWeek(selectedDate)"><i class="bi bi-caret-right-fill"></i></button></div>
            </div>
          </div>
        </div>

        <table class="table table-bordered mt-4">
          <tr style="border-left-color: white; border-right-color: white; border-top-color: white;">
            <td></td>
            <td class="text-center"><h6>Tätigkeiten</h6></td>
            <td class="text-center"><h6>Pausen</h6></td>
            <td class="text-center"><h6>Fahrten</h6></td>
            <td class="text-center"><h6>Ausfälle</h6></td>
          </tr>
          <tbody>
          <tr v-for="(dayData, dayKey, dayIndex) in items" v-bind:key='dayIndex' class="table-row">
            <td class="pt-4 col"><h6>{{ dateStringWithLongWeekday(dayKey) }}</h6></td>


            <td class="pt-4" v-for="index in 4" :key="index">

              <ul class="ps-4" v-if="dayData[Constants.TIMESHEET_ENTRY_TYPE_TASK] && index === 1">
                <li class="timesheet-item" v-for="{ id, task, client, startTime, endTime } in dayData[Constants.TIMESHEET_ENTRY_TYPE_TASK]" :key="id">
                  <small>{{ task }}<br/><span class="text-black-50 m-0 p-0">Kunde: {{ client }}</span><br/>{{ longTimeString(startTime) }} &dash; {{ longTimeString(endTime) }} ({{ timeDifferenceWithUnit(startTime, endTime) }})</small>
                </li>
              </ul>
              <ul v-else-if="dayData[Constants.TIMESHEET_ENTRY_TYPE_BREAK] && index === 2">
                <li class="timesheet-item" v-for="{ id, startTime, endTime } in dayData[Constants.TIMESHEET_ENTRY_TYPE_BREAK]" :key="id">
                  <small>{{ longTimeString(startTime) }} &dash; {{ longTimeString(endTime) }} ({{ timeDifferenceWithUnit(startTime, endTime) }})</small>
                </li>
              </ul>
              <ul v-else-if="dayData[Constants.TIMESHEET_ENTRY_TYPE_RIDE] && index === 3">
                <li class="timesheet-item" v-for="{ id, distance, startTime, endTime } in dayData[Constants.TIMESHEET_ENTRY_TYPE_RIDE]" :key="id">
                  <small>{{ distance }} km<br/>
                    {{ longTimeString(startTime) }} &dash; {{ longTimeString(endTime) }}</small>
                </li>
              </ul>
              <ul v-else-if="dayData[Constants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY] && index === 4">
                <li class="timesheet-item" v-for="{ id, reason, timeOfDay, startTime, endTime } in dayData[Constants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY]" :key="id">
                  <small>{{ reason }}<br/>{{ unavailabilityTime(reason, timeOfDay, startTime, endTime) }}</small>
                </li>
              </ul>
              <span v-else></span>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="row mt-4">
          <div class="col text-end">
            <span>Wochenarbeitsstunden: <b>{{ addTimeUnit(totalWorkingHours) }}</b></span><br/>
            <span>Abgebaute Überstunden: <b>{{ addTimeUnit(totalOvertimeTaken) }}</b></span>
          </div>
        </div>
      </div>
    </div>
      <div class="card-body">
        <button type="button" class="btn btn-primary me-3" @click="print">Drucken</button>
        <router-link to="/admin/users" v-slot="{href, navigate}">
          <button :href="href" @click="navigate" class="btn btn-light">
            Zurück
          </button>
        </router-link>
      </div>
    </div>

</template>

<script>
import {readWeeklyItems, groupBy, getUserProfile } from '@/firebase'
import moment from 'moment'
import { TimeSheetConstants as Constants } from "@/mixins/TimeSheetMixin"


export default {
  name: "WeeklyTimesheet",
  setup() {
    return {
      Constants
    }
  },
  data() {
    return {
      id: null,
      name: null,
      items: null,
      totalWorkingHours: 0,
      totalOvertimeTaken: 0,
    }
  },
  async mounted() {
    this.loadItems()
    await this.loadUserProfile()
  },
  watch: {
    '$route'() {
      this.loadItems()
    }
  },
  methods: {
    loadItems() {
      if (this.$route.params.id) {
        readWeeklyItems(this.$route.params.id, this.selectedDate).then( result => {
          this.items = groupBy(result, ["timestamp", "type"]);
          this.totalWorkingHours = result.filter(doc => doc.type === 'task').reduce((a, b) => a + this.timeDifference(b.startTime, b.endTime), 0)
          this.totalOvertimeTaken = result.filter(doc => doc.type === 'unavailability' && doc.reason === Constants.UNAVAILABILITY_REASON_OVERHOURS).reduce((a, b) => a + this.timeDifference(b.startTime, b.endTime), 0)
        })
      }
    },
    async loadUserProfile() {
      if (this.$route.params.id) {
        const user = await getUserProfile(this.$route.params.id)
        this.id = user.id
        this.name = user.name
      }
    },
    nextWeek() {
      let nextWeekDate  = moment(this.selectedDate).add(1,'weeks').locale("de").format("YYYY-W");
      this.$router.push('/admin/users/' + this.$route.params.id + '/timesheets/' + nextWeekDate)
    },
    previousWeek() {
      let previousWeekDate  = moment(this.selectedDate).add(-1,'weeks').locale("de").format("YYYY-W");
      this.$router.push('/admin/users/' + this.$route.params.id + '/timesheets/' + previousWeekDate)
    },
    async print () {
      await this.$htmlToPaper('printMe');
    },
    unavailabilityTime(availabilityReason, timeOfDay, startTime, endTime) {
      if (availabilityReason === Constants.UNAVAILABILITY_REASON_OVERHOURS) {
        return this.longTimeString(startTime) + " - " + this.longTimeString(endTime) + " (" + this.timeDifferenceWithUnit(startTime, endTime) + ")"
      } else {
        return timeOfDay
      }
    }
  },
  computed: {
    selectedDate() {
      if (this.$route.params.week) {
        return moment(this.$route.params.week, "YYYY-W").toDate();
      } else {
        return new Date()
      }
    },
  }
}
</script>

