import { createStore } from 'vuex'
import { auth, db, uid } from '@/firebase'
import {doc, getDoc} from "firebase/firestore";

export default createStore({
  state: {
    user: null,
    userName: null,
  },
  getters: {
    getUser: state => state.user,
    getUserName: state => state.userName,
  },
  mutations: {
    SET_USER: state => {
      state.user = auth.currentUser;
    },
    SET_USER_NAME(state, payload) {
      state.userName = payload
    },
  },
  actions: {
    setUser: context => {
      context.commit("SET_USER");
    },
    setUserName: async (context) => {
      const userProfileRef = doc(db, "users", uid());
      const userProfile = await getDoc(userProfileRef);
      let userName = null
      if (userProfile.exists) {
        userName = userProfile.data().name
      }
      context.commit("SET_USER_NAME", userName);
    },
  },
  modules: {},
})
