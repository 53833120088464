<template>
  <!-- Modal -->
  <div class="modal fade" id="confirmationDialogModal" tabindex="-1" aria-labelledby="confirmationDialogModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmationDialogModalLabel">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          {{ description }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ abortTitle }}</button>
          <button type="button" class="btn btn-primary" @click="saveFunction" data-bs-dismiss="modal">{{ saveTitle }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  props: ['title', 'description', "saveTitle", "abortTitle", "saveFunction"]
}
</script>
