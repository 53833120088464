<template>
  <div class="modal fade" id="timesheetEntryModal" tabindex="-1" aria-labelledby="timesheetEntryModalLabel" aria-hidden="true">

    <!-- Modal Dialog -->
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="timesheetEntryModalLabel">Neuer Eintrag</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submit">
            <div class="form-floating mb-3">
              <select class="form-select" id="floatingSelect" aria-label="Floating label select example" v-model="entryType">
                <option :value="`${Constants.TIMESHEET_ENTRY_TYPE_TASK}`" selected>{{ Constants.TIMESHEET_ENTRY_TYPE_TASK_NAME }}</option>
                <option :value="`${Constants.TIMESHEET_ENTRY_TYPE_BREAK}`">{{ Constants.TIMESHEET_ENTRY_TYPE_BREAK_NAME }}</option>
                <option :value="`${Constants.TIMESHEET_ENTRY_TYPE_RIDE}`">{{ Constants.TIMESHEET_ENTRY_TYPE_RIDE_NAME }}</option>
                <option :value="`${Constants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY}`">{{ Constants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY_NAME }}</option>
              </select>
              <label for="floatingSelect">Kategorie</label>
            </div>
            <div class="form-floating mb-3" v-if="entryType === Constants.TIMESHEET_ENTRY_TYPE_TASK">
              <select class="form-select" id="floatingSelect" aria-label="Floating label select example" v-model="task">
                <option v-for="task in this.taskSelectValues" v-bind:key="task" :value="task">{{ task }}</option>
              </select>
              <label for="floatingSelect">Tätigkeit</label>
            </div>
            <div class="form-floating mb-3" v-if="entryType === Constants.TIMESHEET_ENTRY_TYPE_TASK">
              <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com" v-model="client" required>
              <label for="floatingInput">Kunde</label>
            </div>
            <div class="form-floating mb-3" v-if="entryType === Constants.TIMESHEET_ENTRY_TYPE_RIDE">
              <select class="form-select" id="floatingSelect" aria-label="Floating label select example" v-model="distance">
                <option v-for="distance in this.distanceSelectValues" v-bind:key="distance" :value="distance">{{ distance }} km</option>
                <option value="210">mehr als 200 km</option>
              </select>
              <label for="floatingSelect">Kilometer zum Bauort</label>
            </div>
            <div class="form-floating mb-3" v-if="entryType === Constants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY">
              <select class="form-select" id="floatingSelect" aria-label="Floating label select example" v-model="unavailabilityReason">
                <option :value="`${Constants.UNAVAILABILITY_REASON_VACATION}`" selected>{{ Constants.UNAVAILABILITY_REASON_VACATION }}</option>
                <option :value="`${Constants.UNAVAILABILITY_REASON_OVERHOURS}`">{{ Constants.UNAVAILABILITY_REASON_OVERHOURS }}</option>
                <option :value="`${Constants.UNAVAILABILITY_REASON_SICKNESS}`">{{ Constants.UNAVAILABILITY_REASON_SICKNESS }}</option>
                <option :value="`${Constants.UNAVAILABILITY_REASON_OTHER}`">{{ Constants.UNAVAILABILITY_REASON_OTHER }}</option>
              </select>
              <label for="floatingSelect">Grund</label>
            </div>
            <div class="row" v-if="timeSelectionIsVisible">
              <div class="col">
                <div class="form-floating">
                  <select class="form-select" id="startTimeInput" aria-label="Floating label select example" v-model="startTime">
                    <option v-for="item in this.startTimeSelectValues" v-bind:key="item" :value="item">{{ item }} Uhr</option>
                  </select>
                  <label for="startTimeInput">Startzeit</label>
                </div>
              </div>
              <div class="col">
                <div class="form-floating">
                  <select class="form-select" id="endTimeInput" aria-label="Floating label select example" v-model="endTime">
                    <option v-for="item in this.endTimeSelectValues" v-bind:key="item" :value="item" :disabled="startTime >= item">{{ item }} Uhr</option>
                  </select>
                  <label for="endTimeInput">Endzeit</label>
                </div>
              </div>
              <div id="timeConflictHint" class="form-text text-danger ms-1 mt-2" v-if="!this.modalIsSaving && this.hasTimeSheetConflict()">{{ timeConflictMessage }}</div>
            </div>
            <div class="form-floating mb-3" v-if="entryType === Constants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY && unavailabilityReason !== Constants.UNAVAILABILITY_REASON_OVERHOURS">
              <select class="form-select" id="floatingSelect" aria-label="Floating label select example" v-model="unavailabilityTime">
                <option value="Gesamter Tag" selected>Gesamter Tag</option>
                <option value="Erste Tageshälftte">Erste Tageshälftte</option>
                <option value="Zweite Tageshälfte">Zweite Tageshälfte</option>
              </select>
              <label for="floatingSelect">Zeitraum</label>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
          <button type="button" class="btn btn-primary" @click="submit" :disabled="isSubmitButtonDisabled || (timeSelectionIsVisible && this.hasTimeSheetConflict())">Speichern</button>
        </div>
      </div>
    </div>
  </div>


  <!-- Date Picker -->
  <div class="row row-cols-2 mb-3 mt-4">
      <div class="col-6 text-start">
        <div class="dropdown">
          <button class="btn btn-outline-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            {{ dateStringWithShortWeekday(selectedDate) }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <div class="row m-0 p-0">
                <div class="col m-0 p-0 ms-2">
                  <router-link :to="`/timesheets/${ this.previousWeekParam(selectedDate) }`" v-if="this.isCurrentYear(this.previousWeekDate(selectedDate))">
                    <i class="bi bi-chevron-left"></i>
                  </router-link>
                </div>
                <div class="col-auto m-0 p-0 text-center">KW {{ currentWeekNumber(selectedDate) }}</div>
                <div class="col m-0 p-0 me-2 text-end">
                  <router-link :to="`/timesheets/${ this.nextWeekParam(selectedDate) }`" v-if="!this.isCurrentWeek(selectedDate)">
                    <i class="bi bi-chevron-right"></i>
                  </router-link>
                </div>
              </div>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li v-for="(date, index) in this.weekdaysUntilDate(selectedDate)" v-bind:key='index'>
              <router-link :to="`/timesheets/${ this.paramDate(date) }`">
                <div class="row m-0 p-0">
                  <div class="col m-0 p-0">
                    <a href="#" class="dropdown-item">
                      <span class="text-black-50" v-if="index === 5">{{ dateStringWithShortWeekday(date) }}</span>
                      <span v-else>{{ dateStringWithShortWeekday(date) }}</span>
                    </a>
                  </div>
                  <div class="col me-2 p-0 mt-1 text-end">
                    <i class="bi bi-check-lg" style="color: green;" v-if="timeSheetForDateHasBeenSubmitted(date)"></i>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-6 text-end">
        <button type="button"
                class="btn btn-primary"
                @click="openModal"
                v-if="!timeSheetHasBeenSubmitted"
                :disabled="addingNewEntryDisabled">
          <i class="bi bi-plus-lg"></i> Hinzufügen
        </button>
      </div>
    </div>
</template>


<script>
import {createEntry} from '@/firebase'
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'
import { de } from 'date-fns/locale';
import moment from 'moment'
import { Modal } from "bootstrap/dist/js/bootstrap.bundle.min.js";
import { TimeSheetConstants as Constants } from "@/mixins/TimeSheetMixin"
const {MAX_NUMBER_OF_DAYS_FOR_SUBMISSION} = require("@/mixins/DateMixin");


export default {
  components: { Datepicker },
  props: ['timeSheetHasBeenSubmitted', 'submittedTimesheets', 'timeRanges'],
  data() {
    return {
      entryType: "task",
      client: "",
      task: "Abbund",
      unavailabilityReason: "Überstunden",
      unavailabilityTime: "Gesamter Tag",
      startTime: Constants.TIMESHEET_ENTRY_DEFAULT_STARTTIME,
      endTime: Constants.TIMESHEET_ENTRY_DEFAULT_ENDTIME,
      distance: 50,
      selectedDate: new Date(),
      modal: null,
      modalIsSaving: false
    };
  },
  setup() {
    return {de, Constants}
  },
  mounted() {
    if (this.$route.params.date) {
      this.selectedDate = moment(this.$route.params.date, "YYYYMMDD").locale("de").toDate()
    }
  },
  watch: {
    '$route'() {
      this.selectedDate = this.selectedDateParam(this.$route.params.date)
    },
    'startTime'(value, oldValue) {
      if (!(value < oldValue)) {
        this.endTime = this.calculateEndTime()
      }
    },
  },
  methods: {
    async submit() {
      this.modalIsSaving = true
      this.closeModal()

      let item = {}
      const selectedDate = moment(this.selectedDate, "YYYYMMDD").startOf('day').toDate()
      const startDate = new Date('1970-01-01T' + this.startTime + ':00Z')
      const endDate = new Date('1970-01-01T' + this.endTime + ':00Z')
      const week = moment(this.selectedDate).locale("de").isoWeek()
      const year = this.selectedDate.getFullYear()
      const typeOrder = this.orderForEntryType(this.entryType)
      const nullDate = Date(Date.UTC(1970,1,1,0,0,0,0))
      if (this.entryType === Constants.TIMESHEET_ENTRY_TYPE_TASK) {
        item = {date: selectedDate, type: this.entryType, typeOrder: typeOrder, client: this.client, task: this.task, startTime: startDate, endTime: endDate}
      } else if (this.entryType === Constants.TIMESHEET_ENTRY_TYPE_RIDE) {
        item = {date: selectedDate, type: this.entryType, typeOrder: typeOrder, distance: this.distance, startTime: startDate, endTime: endDate}
      } else if (this.entryType === Constants.TIMESHEET_ENTRY_TYPE_BREAK) {
        item = { date: selectedDate, type: this.entryType, typeOrder: typeOrder, startTime: startDate, endTime: endDate }
      } else if (this.entryType === Constants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY) {
        if (this.unavailabilityReason === Constants.UNAVAILABILITY_REASON_OVERHOURS) {
          item = { date: selectedDate, type: this.entryType, typeOrder: typeOrder, reason: this.unavailabilityReason, startTime: startDate, endTime: endDate }
        } else {
          item = { date: selectedDate, type: this.entryType, typeOrder: typeOrder, reason: this.unavailabilityReason, timeOfDay: this.unavailabilityTime, startTime: nullDate }
        }
      }
      await createEntry(this.selectedDate, { week: week, year: year, ...item})
    },
    test() {
      const dateString = moment(this.selectedDate).format('YYYYMMDD');
      this.$router.push('/timesheets/' + dateString)
    },
    timeSheetForDateHasBeenSubmitted(date) {
      return Boolean(this.submittedTimesheets && this.submittedTimesheets[this.paramDate(date)]);
    },
    hasTimeSheetConflict() {
      if (this.entryType === Constants.TIMESHEET_ENTRY_TYPE_BREAK &&
          this.startTime > Constants.TIMESHEET_ENTRY_LAST_BREAKTIME) {
        return true
      }
      return this.hasTimeConflict(this.startTime, this.endTime, this.timeRanges)
    },
    openModal() {
      this.modalIsSaving = false
      this.entryType = Constants.TIMESHEET_ENTRY_TYPE_TASK
      this.startTime = this.lastTimeSelectRange(this.timeRanges)
      this.endTime = this.calculateEndTime()

      if (!this.modal) {
        this.modal = new Modal(document.getElementById('timesheetEntryModal'), {})
      }
      this.modal.show()
    },
    closeModal() {
      this.modal.hide()
    },
    calculateEndTime() {
      const index = this.timeSelectValues.indexOf(this.startTime)
      if (index !== -1 && this.timeSelectValues.length > (index + 2)) {
        return this.timeSelectValues[index + 2]
      }
      return this.endTime
    }
  },
  computed: {
    timeConflictMessage() {
      if (this.entryType === Constants.TIMESHEET_ENTRY_TYPE_BREAK &&
          this.startTime > Constants.TIMESHEET_ENTRY_LAST_BREAKTIME) {
        return "Pausen können nur bis " + Constants.TIMESHEET_ENTRY_LAST_BREAKTIME + " Uhr eingetragen werden. Arbeitnehmer sind nach §4 ArbZG gesetzlich dazu verpflichtet nach spätestens 6 Stunden durchgehender Arbeit eine Ruhepause von min. 30 Minuten einzulegen. Es ist daher nicht gestattet Pausen auszulassen bzw. an das Ende der Arbeitszeit zu legen."
      } else {
        return "Es besteht ein zeitlicher Konflikt mit einem anderen Eintrag auf dem Stundenzettel."
      }
    },
    isSubmitButtonDisabled() {
      return this.entryType === Constants.TIMESHEET_ENTRY_TYPE_TASK && (!this.client || this.client.length === 0)
    },
    addingNewEntryDisabled() {
      const today = moment().locale("de")
      return today.diff(this.selectedDate, 'days') > MAX_NUMBER_OF_DAYS_FOR_SUBMISSION
    },
    timeSelectionIsVisible() {
      return this.entryType !== Constants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY || this.unavailabilityReason === Constants.UNAVAILABILITY_REASON_OVERHOURS
    }
  }
}
</script>

<style>
a { text-decoration: none; }
</style>
