import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from './firebase'
import DateMixin from '@/mixins/DateMixin';
import TimeSheetMixin from "@/mixins/TimeSheetMixin"
import VueHtmlToPaper from './plugins/VueHtmlToPaper'

import VueTelInput from 'vue-tel-input'
import './registerServiceWorker'

const globalOptions = {
    mode: 'international',
    defaultCountry: 'de',
    dropdownOptions: {
        disabled: true,
        showDialCodeInSelection: true,
        showFlags: false,
    },
    validCharactersOnly: true,
    autoFormat: true,
    inputOptions: {
        placeholder: ""
    },
    styleClasses: 'form-control-lg p-1 vue-tel-input-custom',
};

let app;
auth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App)
        app.mixin(DateMixin)
        app.mixin(TimeSheetMixin)
        app.use(store)
        app.use(router)
        app.use(VueTelInput, globalOptions)
        app.use(VueHtmlToPaper)
        app.mount('#app')
    }
    store.dispatch("setUser");
})
