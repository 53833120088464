// noinspection ES6CheckImport
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, doc, deleteDoc, updateDoc, getDoc, getDocs, setDoc, where, collectionGroup, orderBy, writeBatch } from 'firebase/firestore';
import { onSnapshot, query } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {ref } from 'vue'
import moment from "moment";
import {convertTimestamp} from "convert-firebase-timestamp";

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

// collection references
const usersId = "users"
const timesheetId = "timesheets";
const entriesId = "entries";

export function isAdmin() {
    return !!auth.currentUser.email;
}

function phoneProviderData() {
    if (auth.currentUser && auth.currentUser.providerData) {
        return auth.currentUser.providerData.find(obj => obj.providerId === 'phone');
    } else {
     return null
    }
}

function uid() {
    if (phoneProviderData()) {
        return phoneProviderData().uid
    } else {
        return "-1"
    }
}

export async function userExists() {
    const userRef = doc(db, usersId, uid());
    const userDoc = await getDoc(userRef);
    return userDoc.exists();
}

// User Functions

export const useLoadUserList = (includeTestUsers) => {
    const userList = ref([])
    let q = query(collection(db, usersId), where('isTestUser', '==', false));
    if (includeTestUsers === true) {
        q = query(collection(db, usersId));
    }
    const unsubscribe = onSnapshot(q, (snapshot) => {
        userList.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), lastSubmission: convertTimestamp(doc.data().lastSubmission) }))
        console.log(userList.value)
    });

    return { users: userList, unsubscribe: unsubscribe }
}

export const createUserProfile = async (id, name, isTestUser) => {
    await setDoc(doc(db, usersId, id), {
        name: name,
        isTestUser: isTestUser
    });
}

export const getUserProfile = async id => {
    const userProfileRef = doc(db, usersId, id);
    const userProfile = await getDoc(userProfileRef);
    return userProfile.exists ? { id: userProfile.id, ...userProfile.data() } : null
}

export const updateUserProfile = async (id, data) => {
    return await updateDoc(doc(db, usersId, id), data);
}


// Timesheet Functions

export const readItems = (date) => {
    const items = ref()
    const dateString = moment(date).locale("de").format("YYYYMMDD").toString()
    const q = query(collection(db, usersId, uid(), timesheetId, dateString, entriesId), where("date", "==", date), orderBy("typeOrder", "asc"), orderBy("startTime", "asc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map(doc => ({id: doc.id, ...doc.data(), startTime: convertTimestamp(doc.data().startTime), endTime: convertTimestamp(doc.data().endTime)}))
        items.value = groupBy(result, ["type"]);
    });
    return { items: items, unsubscribe: unsubscribe }
}

export const createEntry = (date, item) => {
    const dateString = moment(date).local("de").format("YYYYMMDD").toString()
    const data = { userId: uid(), ...item }
    return addDoc(collection(db, usersId, uid(), timesheetId, dateString, entriesId), data)
}

export const deleteItem = (date, id) => {
    const dateString = moment(date).locale("de").format("YYYYMMDD").toString()
    return deleteDoc(doc(db, usersId, uid(), timesheetId, dateString, entriesId, id));
}

export const readWeeklyItems = async (userid, date) => {
    const year = date.getFullYear()
    const week = moment(date).locale("de").isoWeek()

    console.log(userid)
    const q = query(collectionGroup(db, entriesId), where('week', '==', week), where('year', '==', year), where('userId', '==', userid), orderBy("date", "asc"), orderBy("typeOrder", "asc"));
    const docs = await getDocs(q);

    return docs.docs.map(doc => ({id: doc.id, timestamp: convertTimestamp(doc.data().date), ...doc.data(), startTime: convertTimestamp(doc.data().startTime), endTime: convertTimestamp(doc.data().endTime)}))
    //console.log("results = " + Object.values(result))
    //return groupBy(result, ["timestamp", "type"]);
}

// experiments

export const readSubmittedTimesheets = () => {
    const submittedTimesheets = ref()
    const year = (new Date()).getFullYear()

    const q = query(collection(db, usersId, uid(), timesheetId), where("year", "==", year))

    const unsubscribe = onSnapshot(q, (snapshot) => {
        const result = snapshot.docs.map(doc => ({id: doc.id, ...doc.data(), timesheetDate: convertTimestamp(doc.data().timesheetDate), submissionDate: convertTimestamp(doc.data().submissionDate)}))
        submittedTimesheets.value = groupBy(result, ["id"]);
    })

    return { submittedTimesheets: submittedTimesheets, unsubscribe: unsubscribe }
}

export const submitTimesheet = (date) => {
    const dateString = moment(date).locale("de").format("YYYYMMDD").toString()
    const year = date.getFullYear()
    const week = moment(date).locale("de").isoWeek()
    const timeSheetDate = new Date(moment(date).locale("de").format("YYYY-MM-DDT00:00:00Z").toString())
    const submissionDate = new Date()

    const data = {
        year: year,
        week: week,
        timesheetDate: timeSheetDate,
        submissionDate: submissionDate,
    }

    const batch = writeBatch(db);

    const timeSheetRef = doc(db, usersId, uid(), timesheetId, dateString)
    batch.set(timeSheetRef, data);

    const userRef = doc(db, usersId, uid())
    batch.update(userRef, { lastSubmission: submissionDate })

    return batch.commit();

    //return setDoc(doc(db, usersId, uid(), timesheetId, dateString), data)
}

// helper functions

function groupBy(data, keys) {

    if (keys.length === 0) return data;

    // The current key to perform the grouping on:
    const key = keys[0];

    // Loop through the data and construct buckets for
    // all of the unique keys:
    const groups = {};
    for (let i = 0; i < data.length; i++) {
        const row = data[i];
        const groupValue = row[key];

        if (groups[groupValue] === undefined) {
            groups[groupValue] = [];
        }

        groups[groupValue].push(row);
    }

    // Remove the first element from the groups array:
    keys.reverse();
    keys.pop()
    keys.reverse();

    // If there are no more keys left, we're done:
    if (keys.length === 0) return groups;

    // Otherwise, handle further groupings:
    for (const group in groups) {
        groups[group] = groupBy(groups[group], keys.slice());
    }

    return groups;
}

// export utils/refs
export {
    db,
    auth,
    uid,
    groupBy,
    convertTimestamp
}
