import moment from "moment";

export const TimeSheetConstants = {
    TIMESHEET_ENTRY_TYPE_TASK_NAME: "Tätigkeit",
    TIMESHEET_ENTRY_TYPE_BREAK_NAME: "Pause",
    TIMESHEET_ENTRY_TYPE_RIDE_NAME: "Fahrt",
    TIMESHEET_ENTRY_TYPE_UNAVAILABILITY_NAME: "Abwesenheit",

    TIMESHEET_ENTRY_TYPE_TASK: "task",
    TIMESHEET_ENTRY_TYPE_BREAK: "break",
    TIMESHEET_ENTRY_TYPE_RIDE: "ride",
    TIMESHEET_ENTRY_TYPE_UNAVAILABILITY: "unavailability",

    UNAVAILABILITY_REASON_OVERHOURS: "Überstunden",
    UNAVAILABILITY_REASON_SICKNESS: "Krankheit",
    UNAVAILABILITY_REASON_VACATION: "Urlaub",
    UNAVAILABILITY_REASON_OTHER: "Sonstiges",

    TIMESHEET_ENTRY_DEFAULT_STARTTIME: "05:30",
    TIMESHEET_ENTRY_DEFAULT_ENDTIME: "06:00",
    TIMESHEET_ENTRY_LAST_BREAKTIME: "14:00"
}

export default {
    methods: {
        orderForEntryType(entryType) {
            switch (entryType) {
                case TimeSheetConstants.TIMESHEET_ENTRY_TYPE_TASK: return 0
                case TimeSheetConstants.TIMESHEET_ENTRY_TYPE_BREAK: return 1
                case TimeSheetConstants.TIMESHEET_ENTRY_TYPE_RIDE: return 2
                case TimeSheetConstants.TIMESHEET_ENTRY_TYPE_UNAVAILABILITY: return 3
                default: return 4
            }
        },
        hasTimeConflict(startTime, endTime, timeRanges) {
            if (timeRanges) {
                const startDate = new Date('1970-01-01T' + startTime + ':00Z')
                const endDate = new Date('1970-01-01T' + endTime + ':00Z')

                for (const timePair of Object.values(timeRanges)) {
                    const from = moment(timePair.startTime).utc(false).locale("de")
                    const to = moment(timePair.endTime).utc(false).locale("de")

                    const startDateHasConflict = from.isBetween(startDate, endDate, 'minutes', '[)');
                    const endDateHasConflict = to.isBetween(startDate, endDate, 'minutes', '(]');
                    const beforeAfterConlict = from.isBefore(startDate) && to.isAfter(endDate)

                    console.log("hasConflict = ", startDateHasConflict, endDateHasConflict, beforeAfterConlict)

                    if (startDateHasConflict || endDateHasConflict || beforeAfterConlict) {
                        return true
                    }

                }
                return false
            }
        },
        getTimeSheetTimeRanges(items) {
            let result = []
            if (items) {
                for (const [key, value] of Object.entries(items)) {
                    console.log(key)
                    for (const item of value){
                        if (item.startTime && item.endTime) {
                            const data = { startTime: item.startTime, endTime: item.endTime }
                            result.push(data)
                        }
                    }
                }
            }
            return result
        },
        lastTimeSelectRange(timeRanges) {
            if (timeRanges && timeRanges.length > 0) {
                let lastTime = null
                for (const timePair of Object.values(timeRanges)) {
                    const to = moment(timePair.endTime).utc(false).locale("de")
                    if (!lastTime || to.isAfter(lastTime)) {
                        lastTime = to
                    }
                }
                const startTimeString = this.shortTimeString(lastTime)

                if (this.timeSelectValues[this.timeSelectValues.length - 1] === startTimeString || this.timeSelectValues[this.timeSelectValues.length - 2] === startTimeString) {
                    return this.Constants.TIMESHEET_ENTRY_DEFAULT_STARTTIME
                } else {
                    return startTimeString
                }
            }
            return this.Constants.TIMESHEET_ENTRY_DEFAULT_STARTTIME
        }
    },
    computed: {
        startTimeSelectValues() {
            const result = Array.from(this.timeSelectValues)
            result.pop()
            result.pop()
            return result
        },
        endTimeSelectValues() {
            return this.timeSelectValues
        },
        timeSelectValues() {
            let result = [], i, j;
            for(i=5; i<=20; i++) {
                for(j=0; j<4; j++) {
                    const prefix = (i <= 9) ? "0" : ""
                    result.push(prefix + i + ":" + (j===0 ? "00" : 15*j));
                }
            }
            return result
        },
        taskSelectValues() {
            return ['Abbund', 'Beladen', 'Montagearbeiten', 'Dach einlatten', 'Dach decken / klempnern', 'Unterschläge', 'Halle aufräumen', 'Regress', 'Sonstiges']
        },
        distanceSelectValues() {
            let result = [], i;
            for(i=1; i<=40; i++) {
                result.push(i*5)
            }
            return result
        },
    }
}
