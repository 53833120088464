import { Toast } from "bootstrap/dist/js/bootstrap.bundle.min.js"

export default {
    methods: {
        showHomescreenPopup() {
            console.log("Test ...")

            // Detects if device is on iOS
            const isIos = () => {
                const userAgent = window.navigator.userAgent.toLowerCase();
                return /iphone|ipad|ipod/.test( userAgent );
            }
            // Detects if device is in standalone mode
            const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

            console.log("isIOS = " + isIos())
            console.log("isStandaloneMode = " + isInStandaloneMode())

            // Checks if should display install popup notification:
            if (isIos() && !isInStandaloneMode()) {
                console.log("Show popup ....")

                const toastElList = [].slice.call(document.querySelectorAll('.toast'));
                const toastList = toastElList.map(function (toastEl) {
                    return new Toast(toastEl, {autohide: false})
                });
                toastList[0].show()
            }
        }
    }
}
