<template>
  <div class="container admin-layout-content">
    <div class="ms-3 mb-4">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/admin/users" v-slot="{href, navigate}">
              <a :href="href" @click="navigate">Mitarbeiter</a>
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{ pageTitle }}</li>
        </ol>
      </nav>
    </div>

    <div class="card-body">
      <h4 class="card-title">{{ pageTitle }}</h4>
      <p class="card-text">Mitarbeiter können sich über den folgenden Link <a href="#">https://app.stundenzettel.digital</a> anmelden.<br/>Dieser kann über SMS/Whatsapp an dessen Mobiltelefon verschickt werden.</p>
    </div>
    <div class="card-body" style="width: 40rem;">
    <form @submit.prevent="submit">
      <div class="mb-3">
        <label for="phoneNumber" class="form-label">Telefonnummer</label>
        <input type="text" class="form-control form-control-lg" id="phoneNumber" :value="`${id}`" v-if="$route.params.id" disabled>
        <vue-tel-input v-model="phone" @on-input="test" id="phoneNumber" v-else>
          <template v-slot:arrow-icon><span></span></template>
        </vue-tel-input>
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">Name</label>
        <input type="text" class="form-control form-control-lg" id="name" v-model="name">
      </div>
      <div class="mt-5">
        <button type="submit" class="btn btn-primary me-3" :disabled="submitDisabled">Speichern</button>
        <router-link to="/admin/users" v-slot="{href, navigate}">
          <button :href="href" @click="navigate" class="btn btn-light">
            Zurück
          </button>
        </router-link>
      </div>
    </form>
    </div>
  </div>
</template>

<script>
import {createUserProfile, getUserProfile, updateUserProfile} from "@/firebase";
import { ref } from 'vue';


export default {
  name: "UserForm",
  setup() {
    const phone = ref(null);
    return {
      phone,
    };
  },
  data() {
    return {
      id: '',
      name: '',
      submitDisabled: true,
    }
  },
  computed: {
    pageTitle() {
      return (this.$route.params.id) ? "Mitarbeiter bearbeiten" : "Neuen Mitarbeiter anlegen"
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      const user = await getUserProfile(this.$route.params.id)
      this.id = user.id
      this.name = user.name
      this.submitDisabled = false
    }
  },
  methods: {
    test(number, phoneObject) {
      console.log(phoneObject.number)
      this.id = phoneObject.number
      this.submitDisabled = !phoneObject.valid
      console.log(phoneObject.number)
    },
    async submit() {
      if (this.$route.params.id) {
        // update user object
        //await axios.put(`products/${this.$route.params.id}`, data);
        await updateUserProfile(this.id, { name: this.name })
      } else {
        await createUserProfile(this.id, this.name, false)
        this.id = ''
        this.name = ''
      }
      await this.$router.push('/admin/users')
    },
  }
}
</script>
