import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '@/firebase'
import DailyTimeSheet from "@/views/DailyTimeSheet";
import UserForm from "@/components/UserForm";
import UserList from "@/components/UserList";
import WeeklyTimesheet from "@/components/WeeklyTimesheet"
import moment from 'moment'
import {SUNDAY, isCurrentWeek, MAX_NUMBER_OF_WORKDAYS} from "@/mixins/DateMixin";

export const Role = {
  Admin: 'Admin',
  User: 'User'
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/UserLogin.vue'),
  },
  {
    path: '/admin/login',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "login" */ '../views/AdminLogin.vue'),
  },
  {
    component: () => import(/* webpackChunkName: "login" */ '../views/AdminLayout.vue'),
    children: [
      //{ path: "/admin", redirect: "/admin/users" },
      { path: "/admin/users", component: UserList, meta: { requiresAuth: true, adminPath: true } },
      { path: "/admin/users/:id/timesheets/:week?", component: WeeklyTimesheet, meta: { requiresAuth: true, adminPath: true } },
      { path: "/admin/users/create", component: UserForm, meta: { requiresAuth: true, adminPath: true } },
      { path: "/admin/users/edit/:id", component: UserForm, meta: { requiresAuth: true, adminPath: true } },
    ]
  },
  {
    //path: "",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserLayout.vue'),
    children: [
      //{ path: "", redirect: "/timesheets" },
      { path: "/timesheets/:date?", component: DailyTimeSheet, meta: { requiresAuth: true } },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/"
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const isAdminPath = to.matched.some(x => x.meta.adminPath)

  if (requiresAuth && !auth.currentUser) {
    return (isAdminPath) ? next('/admin/login') : next('/login')
  }

  if (to.path === '/') {
    if (auth.currentUser) {
      return (isAdminUser()) ? next('/admin/users') : next('/timesheets')
    } else {
      return next('/login')
    }
  }

  if (isAdminUser()) {
    if (isAdminPath) {
      return next()
    } else {
      return next("/admin/users")
    }
  } else {
    if (isAdminPath) {
      return next("/timesheets")
    }
  }

  // If current day is sunday -> route to previous Day
  if (moment().locale("de").weekday() === SUNDAY &&
      to.path.startsWith("/timesheets") &&
      !to.params.date) {
    const previousDay = moment().locale("de").add(-1, 'days').format("YYYYMMDD").toString()
    return next("/timesheets/" + previousDay)
  }

  if (to.path.startsWith("/timesheets/") && to.params.date) {
    if (!isValidParamDateString(to.params.date)) {
      return next('/timesheets')
    }
  }

  // Logged in user -> redirect to timesheets
  if (to.path.startsWith("/login") && auth.currentUser && !auth.currentUser.email) {
    return next("/timesheets")
  }

  return next()
})

/// Helper Methods

function isAdminUser() {
  if (auth.currentUser) {
    return !!(auth.currentUser.email)
  }
  return false
}

function isValidParamDateString(dateString) {
  const date = moment(dateString, "YYYYMMDD").locale("de")
  const now = moment().locale("de")
  const startOfWeek = date.clone().startOf('isoWeek');
  let endOfWeek = date.clone().endOf('isoWeek');

  if (date.isAfter(now, 'days')) {
    return false
  }

  if (date.clone().weekday() >= MAX_NUMBER_OF_WORKDAYS) {
    return false
  }

  if (isCurrentWeek(date.toDate())) {
    endOfWeek = now
  }

  return date.isBetween(startOfWeek, endOfWeek, 'days', '[]');
}

export default router
