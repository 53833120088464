<template>
  <!--
  <div class="container text-center mt-5 mb-5">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">Stundenzettel</router-link>
  </div>
  -->
  <router-view/>
</template>

<script>
//importing bootstrap 5
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import 'bootstrap-icons/font/bootstrap-icons.css'
//import '@/assets/scss/custom.scss'

import 'vue-tel-input/dist/vue-tel-input.css';
import '@/assets/css/custom.css'

export default {

}
</script>
