/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log("Service worker has been registered and now polling for updates.")
      setInterval(() => {
        registration.update().then(() => {
          console.log("registration.update() finished")
        })
      }, 5000) // every 5 seconds
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; Refreshing...')

      /*
      document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
      )

      setTimeout(() => {
        window.location.reload(true)
      }, 1000)
      */
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
