import moment from "moment";

export const MAX_NUMBER_OF_WORKDAYS = 6
export const MIN_NUMBER_OF_WORKDAYS = 5
export const MONDAY = 0
export const TUESDAY = 1
export const WEDNESDAY = 2
export const THURSDAY = 3
export const FRIDAY = 4
export const SATURDAY = 5
export const SUNDAY = 6

export const MAX_NUMBER_OF_DAYS_FOR_SUBMISSION = 30

export function isCurrentWeek(date) {
    const selectedYear = date.getFullYear()
    const selectedWeek = moment(date).locale("de").isoWeek()

    const now = new Date()
    const year = now.getFullYear()
    const week = moment(now).locale("de").isoWeek()

    return Boolean(year === selectedYear && week === selectedWeek)
}

export default {
    methods: {
        weekdaysUntilDate(date) {
            const lastWorkDayOfWeek = MAX_NUMBER_OF_WORKDAYS - 1
            const weekdays = [];

            const selectedDate = moment(date).locale("de");
            const currentWeekday = moment().locale("de").clone().weekday()
            const selectedEndOfWeek = selectedDate.clone().endOf('isoWeek').weekday();

            let weekday = this.isCurrentWeek(date) ? currentWeekday : selectedEndOfWeek
            weekday = (weekday <= lastWorkDayOfWeek) ? weekday : lastWorkDayOfWeek;

            for (let i = 0; i <= weekday; i++) {
                weekdays.push(selectedDate.clone().weekday(i).toDate())
            }
            return weekdays
        },
        weekdayName(date) {
            return moment(date).locale("de").format("dddd")
        },
        formatMonthAndYear(date) {
          return moment(date).locale("de").format("MMMM YYYY")
        },
        isToday() {

        },
        currentYear() {
            return (new Date()).getFullYear()
        },
        isCurrentYear(date) {
            const selectedYear = date.getFullYear()
            const now = new Date()
            const year = now.getFullYear()

            return Boolean(year === selectedYear)
        },
        isCurrentWeek,
        currentWeekNumber(date) {
            return moment(date).locale("de").isoWeek()
        },
        nextWeekParam(date) {
            return moment(date).add(1,'weeks').locale("de").format('YYYYMMDD');
        },
        nextWeek(date) {
            return moment(date).add(1,'weeks').locale("de").isoWeek()
        },
        nextWeekDate(date) {
            return moment(date).add(1,'weeks').locale("de").toDate()
        },
        previousWeekParam(date) {
            return moment(date).add(-1,'weeks').locale("de").format('YYYYMMDD');
        },
        previousWeek(date) {
            return moment(date).add(-1,'weeks').locale("de").isoWeek()
        },
        previousWeekDate(date) {
            return moment(date).add(-1,'weeks').locale("de").toDate()
        },
        dateStringWithLongWeekday(date) {
            return moment(date).locale("de").format("dddd, DD.MM.YY")
        },
        dateStringWithShortWeekday(date) {
            return moment(date).locale("de").format("dd, DD.MM.")
        },
        longTimeString(date) {
            return moment(date).utc(false).locale("de").format("HH:mm [Uhr]")
        },
        shortTimeString(date) {
            return moment(date).utc(false).locale("de").format("HH:mm")
        },
        paramDate(date) {
            return moment(date).locale("de").format("YYYYMMDD")
        },
        selectedDateParam(dateParam) {
            const dateString = (dateParam) ? dateParam : moment(new Date()).locale("de").format("YYYYMMDD")
            return moment(dateString, "YYYYMMDD").startOf('day').toDate()
        },
        addTimeUnit(time) {
            const unit = (time === 1) ? " Stunde" : " Stunden"
            return String(time + unit)
        },
        timeDifference(startTime, endTime) {
            const time1 = moment(startTime)
            const time2 = moment(endTime)
            return time2.diff(time1, 'hours', true)
        },
        timeDifferenceWithUnit(startTime, endTime) {
            const result = this.timeDifference(startTime, endTime)
            return this.addTimeUnit(result)
        },
    }
}
