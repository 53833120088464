<template>
  <div class="container admin-layout-content">
    <div class="row mb-5">
      <div class="col-auto">
        <router-link to="/admin/users/create" v-slot="{href, navigate}">
          <button :href="href" @click="navigate" class="btn btn-outline-dark">
            Neuen Mitarbeiter anlegen
          </button>
        </router-link>
      </div>
      <!--
      <div class="col">
        <router-link to="/admin/users/create" v-slot="{href, navigate}">
          <button :href="href" @click="navigate" class="btn btn-outline-secondary">
            Archiv
          </button>
        </router-link>
      </div>
      -->
    </div>
    <div class="row row-cols-auto">
      <div class="col mb-4" v-for="{ id, name, lastSubmission } in users" :key="id">
        <div class="card" style="width: 18rem;">
          <div class="card-body">
            <h5 class="card-title">{{ name }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{ id }}</h6>
            <p class="card-text"><small>Letzte Abgabe:<br/>{{ lastSubmission ? dateStringWithLongWeekday(lastSubmission) : 'Keine' }}</small></p>
            <router-link :to="`/admin/users/${id}/timesheets`">
              <button type="button" class="btn btn-primary btn-sm me-2">Anzeigen</button>
            </router-link>
            <router-link :to="`/admin/users/edit/${id}`">
              <button type="button" class="btn btn-light btn-sm">Bearbeiten</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useLoadUserList } from '@/firebase'

export default {
  name: "UserList",
  data() {
    return {
      users: null,
      unsubscribe: null
    }
  },
  mounted() {
    this.loadUsers()
  },
  watch: {
    '$route'() {
      this.loadUsers()
    }
  },
  methods: {
    loadUsers() {
      if (this.unsubscribe) { this.unsubscribe() }

      const includeTestUsers = (this.$route.query.includeTestUsers === 'true')
      const { users, unsubscribe } = useLoadUserList(includeTestUsers)

      this.users = users
      this.unsubscribe = unsubscribe
    }
  }
}
</script>
