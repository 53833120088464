<template>
  <div class="position-fixed bottom-0 start-50 translate-middle-x p-3" style="z-index: 11">
    <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong class="me-auto">Auf dem iPhone installieren</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        Installieren Sie diese Web-App auf ihren iPhone:<br/>Im unteren Menü auf <img src="@/assets/share-icon.png" alt="" width="18" height="18" class="p-0 m-0"> drücken und dann <strong>"Zum Home-Bildschirm hinzufügen"</strong> auswählen.
      </div>
    </div>
  </div>

  <ConfirmationDialog
      title="Studenzettel einreichen"
      description="Hiermit bestätige ich, dass ich die gesetzlichen Pausenzeiten eingehalten habe und alle Arbeits- und Pausenzeiten korrekt eingetragen wurden."
      saveTitle="Bestätigen"
      abortTitle="Abbrechen"
      :saveFunction="submitTimesheet"/>

  <div class="alert alert-primary mt-4" role="alert" v-if="updateExists">
    <a @click="refreshApp" class="text-primary">Ein Update ist verfügbar.<i class="bi bi-chevron-right"></i></a>
  </div>

  <div class="alert alert-primary mt-4" role="alert" v-if="!this.isCurrentWeek(selectedDate)">
    <a @click="$router.push('/timesheets')" class="text-primary">Zum heutigen Stundenzettel springen <i class="bi bi-chevron-right"></i></a>
  </div>

  <TimeSheetEntryCreate
      v-if="userExists"
      v-bind:timeSheetHasBeenSubmitted="timeSheetHasBeenSubmitted"
      v-bind:submittedTimesheets="submittedTimesheets"
      v-bind:timeRanges="timeRanges" />

  <div v-else>
    <div class="alert alert-danger mt-4" role="alert" v-if="userDoesNotExistMessage">{{this.userDoesNotExistMessage}}</div>
  </div>

  <TimeSheetEntryList
      v-if="userExists"
      v-bind:timeSheetHasBeenSubmitted="timeSheetHasBeenSubmitted"
      v-bind:timeSheetSubmissionDate="timeSheetSubmissionDate"
      v-on:childToParent="childIsLoading"
      v-on:timeRangeChanged="timeRangeChanged" />
</template>

<script>
import TimeSheetEntryCreate from "@/components/TimeSheetEntryCreate";
import TimeSheetEntryList from "@/components/TimeSheetEntryList";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { userExists, readSubmittedTimesheets, submitTimesheet } from '@/firebase'
import UpdateMixin from '@/mixins/UpdateMixin'
import HomescreenMixin from  '@/mixins/AddToHomescreenMixin'

export default {
  name: "TimeSheet",
  components: {TimeSheetEntryList, TimeSheetEntryCreate, ConfirmationDialog},
  mixins: [UpdateMixin, HomescreenMixin],
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    selectedDate() {
      return this.selectedDateParam(this.$route.params.date)
    },
  },
  data() {
    return {
      submittedTimesheetsYear: 0,
      submittedTimesheets: null,
      unsubscribeFromTimeSheetConfirmations: null,

      timeSheetHasBeenSubmitted: false,
      timeSheetSubmissionDate: null,

      userExists: false,
      userDoesNotExistMessage: "",

      timeRanges: [],
    };
  },
  mounted() {
    console.log("Test")

    this.selectedWeek = this.currentWeekNumber(this.selectedDateParam(this.$route.params.date))
    this.loadTimesheetSubmissions()

    userExists().then((result) => {
      this.userExists = result;
      this.userDoesNotExistMessage = (!result) ? "Ihr Nutzerprofil wurde noch nicht angelegt. Bitte wenden Sie sich an den Administrator."  : ""
    })

    this.showHomescreenPopup()
  },
  updated() {
    if (this.currentYear() > this.submittedTimesheetsYear) {
      console.log("New Year detected. Reloading timesheet data ...")
      // if new year, then reload the submissions list for current year
      this.loadTimesheetSubmissions()
    }
  },
  watch: {
    '$route'() {
      this.updateTimeSheetInformation(this.submittedTimesheets)
    },
    'submittedTimesheets'(value) {
      this.updateTimeSheetInformation(value)
    }
  },
  methods: {
    loadTimesheetSubmissions() {
      if (this.unsubscribeFromTimeSheetConfirmations) { this.unsubscribeFromTimeSheetConfirmations() }

      const {submittedTimesheets, unsubscribe} = readSubmittedTimesheets();
      this.submittedTimesheets = submittedTimesheets
      this.unsubscribeFromTimeSheetConfirmations = unsubscribe
      this.submittedTimesheetsYear = this.currentYear()
    },
    childIsLoading (value) {
      this.isLoading = value
    },
    updateTimeSheetInformation(value) {
      const selectedDateString = this.paramDate(this.selectedDate)
      if (value && value[selectedDateString]) {
        this.timeSheetHasBeenSubmitted = true
        this.timeSheetSubmissionDate = value[selectedDateString].submissionDate
      } else {
        this.timeSheetHasBeenSubmitted = false
        this.timeSheetSubmissionDate = null
      }
    },
    submitTimesheet() {
      console.log("submitTimesheet = " + this.selectedDate)
      submitTimesheet(this.selectedDate)
    },
    timeRangeChanged(value) {
      console.log("Time Ranges = " + value)
      this.timeRanges = value
    }
  },
};
</script>
